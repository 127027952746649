/* --- MOBILE STYLES --- */

.landing .name {
  font-size: 48px;
  letter-spacing: -3px;
}
.landing .description {
  font-size: 22px;
  letter-spacing: 1.5px;
}
.landing .description-location {
  font-size: 18px;
  letter-spacing: 1.3px;
}

.landingImage {
  right: 0px;
}

.landing .socialIcons {
  display: flex;
  position: absolute;
  left: 50px;
  bottom: 20px;
}


.landing {
  height: calc(100% - 93px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  padding-bottom: 28px,
}

/* --- MEDIA QUERIES --- */

@media (min-width: 750px) {

  .landing {
    justify-content: flex-start;
    padding: 50px 10%;
  }

  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 44px;
  }

  .landing .description-location {
    font-size: 32px;
  }

  .landingImage {
    bottom: 0;
    right: 80px;
  }

  .landing .socialIcons {
    flex-direction: column;
  }

  .textContainer {
    margin-top: -150px;
  }
}

@media (max-width: 749px) {

  .landing {
    justify-content: center;
  }


  .textContainer {
    margin-top: -788px;
  }

  .landingImage {
    right: 0px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .name {
    padding-bottom: 0;
  }
  .logo {
    height: 48px;
  }
  
}

@media (max-width: 600px) {
  .logo {
    height: 40px;
  }
  .textContainer {
    /*margin-top: -640px;*/
    position: absolute;
    top: 90px;
    margin: 0;
  }
  .name {
    padding-bottom: 20px;
  }
  .landing-book-now-container{
    position: fixed;
    bottom: 0;
    margin: 0;
    left: 45vw;
  }
}



.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
  padding: 0 10%;
  gap: 20px;
}

/* --- MOBILE STYLES --- */

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: var(--spacing);
}

.faq {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

ul {
  margin-top: 20px;
  margin-bottom: 20px;
}

li {
  list-style-type: disc;
}
/* --- MOBILE STYLES --- */

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: var(--spacing);
}

.services {
  color: white;
  font-size: 32pt;
  display: flex;
  flex-direction: column;
  gap: 90px;
}